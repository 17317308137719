import React, { useState } from 'react';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const nextSteps = [
  {
    step: 'Re-teach key concepts: Focus on areas where students struggled.',
    resource: '[Additional Resource]',
    completed: false
  },
  {
    step: 'Spiral review: Revisit the concept in future lessons',
    resource: '[6.NS.B.4]',
    completed: false
  },
  {
    step: 'Introduce a new strategy: Show students a different approach.',
    resource: '[Resource download]',
    completed: false
  }
];

const MasteryLevelTable = () => {
  const [checkedState, setCheckedState] = useState(
    nextSteps.map((item) => item.completed)
  );

  const handleCheckboxChange = ({ index }) => {
    setCheckedState((prevCheckedState) =>
      prevCheckedState.map((item, idx) => (idx === index ? !item : item))
    );
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <Typography
          variant="S-TEXT-1"
          className={styles.nextStepHeader}
          color={colors.white}
        >
          NEXT STEP (Check when completed)
        </Typography>
        <Typography
          variant="S-TEXT-1"
          className={styles.resourcesHeader}
          color={colors.white}
        >
          RESOURCES
        </Typography>
      </div>
      {nextSteps.map((item, index) => (
        <div key={index} className={styles.tableRow}>
          <div className={styles.stepCell}>
            <input
              type="checkbox"
              checked={checkedState[index]}
              onChange={() => handleCheckboxChange({ index })}
              className={styles.customCheckbox}
            />
            <Typography
              className={styles.stepDescription}
              variant="B-Text-4"
              color={colors.blue10}
            >
              {item.step}
            </Typography>
          </div>
          <div className={styles.resourceCell}>
            <Typography
              className={styles.resourceDescription}
              color={'#D03673'}
              variant="B-Text-4"
            >
              {item.resource ? item.resource : '-'}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MasteryLevelTable;
