import React, { useCallback } from 'react';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Dialog from 'components/Dialog';
import Image from 'components/Image';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StudentWorkViewDialog = ({
  studentWork,
  studentWorks,
  setSelectedStudentWork,
  onClose
}) => {
  const onLeftArrowClick = useCallback(() => {
    const currentIndex = studentWorks.indexOf(studentWork);
    const nextIndex =
      currentIndex === 0 ? studentWorks.length - 1 : currentIndex - 1;
    const nextStudentWorkUrl = studentWorks[nextIndex];
    setSelectedStudentWork(nextStudentWorkUrl);
  }, [studentWorks, studentWork, setSelectedStudentWork]);

  const onRightArrowClick = useCallback(() => {
    const currentIndex = studentWorks.indexOf(studentWork);
    const nextIndex =
      currentIndex === studentWorks.length - 1 ? 0 : currentIndex + 1;
    const nextStudentWorkUrl = studentWorks[nextIndex];
    setSelectedStudentWork(nextStudentWorkUrl);
  }, [studentWorks, studentWork, setSelectedStudentWork]);

  return (
    <div className={styles.dialogContainer}>
      <Dialog
        open={true}
        onClose={onClose}
        className={styles.dialog}
        classes={{ paper: styles.paperRoot }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.dialogContent}>
          <div className={styles.imageContainer}>
            <Image src={studentWork.imageUrl} className={styles.image} />
          </div>

          <div className={styles.categoryContainer}>
            {!_.isEmpty(studentWorks) && (
              <ArrowLeftIcon
                fontSize="large"
                htmlColor={colors.grey3}
                className={styles.arrow}
                onClick={onLeftArrowClick}
              />
            )}
            <Typography
              variant="H-TEXT-3"
              component="h5"
              className={styles.strategyText}
            >
              {studentWork.category}
            </Typography>
            {!_.isEmpty(studentWorks) && (
              <ArrowRightIcon
                fontSize="large"
                htmlColor={colors.grey3}
                className={styles.arrow}
                onClick={onRightArrowClick}
              />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StudentWorkViewDialog;
