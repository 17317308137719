import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import SvgIcon from '@material-ui/core/SvgIcon';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { TEACHERS_CAPTURE_URL } from 'config/urls';
import _ from 'lodash';

import {
  GridView,
  InfiniteScrollContainer,
  LoadingGridViewSkeleton
} from 'pages/Teachers/StudentPortfolio/components';
import { splitAssignmentsToMonths } from 'utils/assignments';
import { STUDENT_PORTFOLIO_STANDARD_TABS } from 'utils/constants';
import { getStandardSiblings } from 'utils/standards';

import Button from 'components/Button';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import StandardNode from 'components/StandardNode';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StandardsView = ({
  student,
  filters,
  initialFilters,
  standards,
  standardsLoading,
  activeStandardTab,
  studentAssignments,
  loadNextPage
}) => {
  const [standardIdsToRender, setStandardIdsToRender] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(standards)) {
      const standardsToExpand = !_.isEqual(initialFilters, filters)
        ? standards
        : standards.filter((standard) => _.inRange(standard.level, 0, 3));

      const initiallyOpenedStandards = _.map(standardsToExpand, 'id');

      setStandardIdsToRender(initiallyOpenedStandards);
    }
  }, [standards, filters, initialFilters]);

  const monthsAssignments = useMemo(
    () =>
      splitAssignmentsToMonths({
        assignments: studentAssignments.data.results
      }),
    [studentAssignments.data.results]
  );

  const getStandardDirectChildren = useCallback(
    ({ standard }) => _.filter(standards, { parent_id: standard.id }),
    [standards]
  );

  const transformedStandards = useMemo(
    () =>
      _.map(standards, (standard) => {
        return {
          ...standard,
          direct_children: getStandardDirectChildren({ standard })
        };
      }),
    [standards, getStandardDirectChildren]
  );

  const getStandardChildren = ({ children, standard }) => {
    const directChildren = getStandardDirectChildren({ standard });

    children.push(directChildren);

    _.each(directChildren, (child) => {
      getStandardChildren({ children, standard: child });
    });

    return _.flatten(children);
  };

  const getStandardParent = ({ standard }) =>
    _.find(transformedStandards, {
      id: standard.parent_id
    });

  const getStandardParents = ({ parents, standard }) => {
    if (standard.parent_id) {
      const parent = getStandardParent({ standard });

      if (parent) {
        parents.push(parent);
      }

      if (parent?.parent_id) {
        getStandardParents({ parents, standard: parent });
      }
    }

    return _.flatten(parents);
  };

  const parentOnLevelIsOnlyChildOrLastChild = ({ level, standard }) => {
    const standardAllParents = getStandardParents({
      parents: [],
      standard
    });

    const parentOnLevel = _.first(_.filter(standardAllParents, { level }));

    if (!parentOnLevel) {
      return false;
    }

    const parentSiblings = getStandardSiblings({
      standard: parentOnLevel,
      standards: transformedStandards
    });

    const parentOnLevelIsOnlyChild = _.isEmpty(parentSiblings);

    const standardsOnLevel = _.filter(transformedStandards, {
      level,
      parent_id: parentOnLevel.parent_id
    });

    const parentOnLevelIsLastChild =
      _.last(standardsOnLevel).id === parentOnLevel.id;

    return parentOnLevelIsOnlyChild || parentOnLevelIsLastChild;
  };

  const closeStandard = ({ standard }) => {
    // Get all standard children down the tree
    const standardsToToggle = getStandardChildren({ children: [], standard });
    const standardIdsToToggle = _.map(standardsToToggle, 'id');

    setStandardIdsToRender(
      _.filter(
        standardIdsToRender,
        (standardId) => !_.includes(standardIdsToToggle, standardId)
      )
    );
  };

  const openStandard = ({ standard }) => {
    // Get all direct standard children
    const standardsToToggle = getStandardDirectChildren({ standard });

    setStandardIdsToRender(
      _.xor(standardIdsToRender, _.map(standardsToToggle, 'id'))
    );
  };

  const openStandardAndAllChildren = ({ standard }) => {
    const standardsToToggle = getStandardChildren({ children: [], standard });
    const standardIdsToToggle = _.map(standardsToToggle, 'id');

    setStandardIdsToRender(_.union(standardIdsToRender, standardIdsToToggle));
  };

  const noResultsPlaceholder = useMemo(
    () => (
      <NoResultsPlaceholder
        text="to get started"
        button={
          <Button
            color="blue"
            className={styles.captureWorkButton}
            noTypography
            href={TEACHERS_CAPTURE_URL}
          >
            <SvgIcon className={styles.cameraIcon} component={CameraAltIcon} />
            <Typography variant="H-TEXT-3">capture work</Typography>
          </Button>
        }
      />
    ),
    []
  );

  return (
    <>
      {standardsLoading && <StandardNode.Placeholder count={3} />}

      {!standardsLoading &&
        _.isEmpty(transformedStandards) &&
        (activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA ||
          activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH) &&
        noResultsPlaceholder}

      {!standardsLoading &&
        !_.isNil(student) &&
        !_.isEmpty(transformedStandards) &&
        (activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA ||
          activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH) && (
          <>
            {_.map(transformedStandards, (standard) => {
              // A standard is opened if all of their direct children are rendered
              const standardIsOpened = _.every(
                standard.direct_children,
                (child) => _.includes(standardIdsToRender, child.id)
              );

              return (
                <Collapse
                  in={_.includes(standardIdsToRender, standard.id)}
                  key={standard.id}
                >
                  <StandardNode
                    forStudents={false}
                    standard={standard}
                    standards={transformedStandards}
                    opened={standardIsOpened}
                    openStandard={openStandard}
                    closeStandard={closeStandard}
                    openStandardAndAllChildren={openStandardAndAllChildren}
                    parentOnLevelIsOnlyChildOrLastChild={
                      parentOnLevelIsOnlyChildOrLastChild
                    }
                    student={student}
                  />
                </Collapse>
              );
            })}
          </>
        )}

      {studentAssignments.loading &&
        _.isEmpty(studentAssignments.data.results) &&
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS && (
          <div className={styles.gridViewContainer}>
            <LoadingGridViewSkeleton />
          </div>
        )}

      {!studentAssignments.loading &&
        _.isEmpty(studentAssignments.data.results) &&
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS &&
        noResultsPlaceholder}

      {activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS &&
        !_.isNil(student) && (
          <InfiniteScrollContainer
            length={studentAssignments.data.results.length}
            fetchNext={loadNextPage}
            hasNext={studentAssignments.data.next}
          >
            <div className={styles.gridViewContainer}>
              <GridView
                monthsAssignments={monthsAssignments}
                student={student}
              />
            </div>
          </InfiniteScrollContainer>
        )}
    </>
  );
};

export default StandardsView;
