import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

import { TEACHERS_BULK_UPLOAD_ASSIGN_ASSIGNMENT_URL } from 'config/urls';
import { ReactComponent as UploadIcon } from 'images/file_upload.svg';
import _ from 'lodash';
import { createBulkUpload } from 'sdk';

import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import AttachmentUploadOverlay from 'pages/Teachers/shared/AttachmentUploadOverlay';
import { colors } from 'theme/palette';
import { uploadFile } from 'utils/files';
import { notifyError, notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import FileLine from './components/FileLine';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const WorkUpload = () => {
  const [fileThatIsBeingUploaded, setFileThatIsBeingUploaded] = useState(null);

  const uploading = !_.isNil(fileThatIsBeingUploaded);

  const history = useHistory();

  const onDrop = async (files) => {
    const file = files[0];

    if (!_.isNil(file)) {
      setFileThatIsBeingUploaded(file);

      const uploadedFileId = await uploadFile({
        blob: file,
        name: file.name,
        maxSize: {
          sizeInBytes: 100 * 1_048_576,
          sizeAsString: '100MB'
        },
        trackMixpanelEventOnFailure: true
      });

      if (_.isNil(uploadedFileId)) {
        setFileThatIsBeingUploaded(null);
        return;
      }

      const data = { pdf: uploadedFileId };

      const {
        success,
        data: bulkUpload,
        errors
      } = await createBulkUpload({
        data
      });

      if (success) {
        history.push(
          reverse(TEACHERS_BULK_UPLOAD_ASSIGN_ASSIGNMENT_URL, {
            bulkUploadId: bulkUpload.id
          })
        );
      } else {
        notifyErrors(errors);
        setFileThatIsBeingUploaded(null);
      }
    }
  };

  const onDropRejected = (files) => {
    return _.forEach(files, () => notifyError('Unsupported file type.'));
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: '.pdf',
    onDropRejected
  });

  return (
    <Layout currentStepIndex={0} isCloseButtonDisabled={uploading}>
      <div
        {...getRootProps({
          onClick: (event) => event.stopPropagation()
        })}
      >
        <input {...getInputProps()} />
        {isDragActive && <AttachmentUploadOverlay />}
        <div className={styles.container}>
          <Typography variant="H-TEXT-1" className={styles.header}>
            {uploading ? 'Uploading...' : 'Bulk Work Upload'}
          </Typography>

          {uploading ? (
            <FileLine file={fileThatIsBeingUploaded} />
          ) : (
            <>
              <div className={styles.upload} onClick={open}>
                <div className={styles.uploadInnerContainer}>
                  <UploadIcon
                    className={styles.uploadIcon}
                    color={colors.blue2}
                  />
                  <Typography
                    textAlign="center"
                    variant="S-TEXT-1"
                    className={styles.uploadText}
                  >
                    Upload PDF of Scanned work
                  </Typography>
                  <Typography variant="B-Text-2" color={colors.blue3}>
                    or email scan to work@edlight.com
                  </Typography>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default WorkUpload;
