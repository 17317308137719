import React, { useContext } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as MainIcon } from './assets/edlightMain.svg';
import { ReactComponent as InfoIcon } from './assets/info.svg';
import styles from './styles.module.scss';

const RecommendedNextSteps = ({ nextSteps }) => {
  const { user } = useContext(UserContext);

  const recommendedNextStepsText = user.is_PLAS_user
    ? 'аdvancing questions'
    : 'recommended next steps';

  return (
    <div className={styles.recommendedContainer}>
      <div className={styles.recommendedSection}>
        <Typography
          variant="H-TEXT-3"
          component="h5"
          className={styles.recommendedStepsText}
        >
          {recommendedNextStepsText}
        </Typography>
        <Tooltip
          classes={{
            tooltip: styles.tooltipInfo,
            arrow: styles.tooltipArrow
          }}
          title={
            <Typography
              variant="B-Text-3"
              component="h5"
              classes={{ root: styles.tooltipText }}
            >
              Student Facing Next Step
            </Typography>
          }
          placement="top"
          arrow={true}
        >
          <SvgIcon component={InfoIcon} className={styles.infoIcon} />
        </Tooltip>
      </div>
      <div className={styles.recommendedSection}>
        <Typography
          variant="B-Text-3"
          component="div"
          className={styles.nextStepsText}
        >
          {nextSteps.map((step, index) => (
            <div key={index} className={styles.stepContainer}>
              <SvgIcon component={MainIcon} />
              <span>{step}</span>
            </div>
          ))}
        </Typography>
      </div>
    </div>
  );
};

export default RecommendedNextSteps;
