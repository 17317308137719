import React from 'react';

import { useOverallResponseOverview } from 'sdk';

import EmptyDataState from 'pages/Teachers/InsightsV2/components/EmptyDataState';
import LoadingSkeleton from 'pages/Teachers/InsightsV2/components/LoadingSkeleton';
import MasteryLevelGroup from 'pages/Teachers/InsightsV2/components/MasteryLevelGroup';
import ProgressBar from 'pages/Teachers/InsightsV2/components/ProgressBar';
import {
  CELEBRATE,
  CELEBRATE_COLOR,
  GENERAL_CONCEPTUAL_UNDERSTANDING,
  INTERVENE,
  INTERVENE_COLOR,
  SIGNIFICANT_ERRORS_IN_WORK,
  SUPPORT,
  SUPPORT_COLOR,
  WORK_IS_COMPLETE_AND_CORRECT
} from 'pages/Teachers/InsightsV2/constants';

import { ReactComponent as CelebrateIcon } from './assets/celebrate-icon.svg';
import { ReactComponent as InterveneIcon } from './assets/intervene-icon.svg';
import { ReactComponent as SupportIcon } from './assets/support-icon.svg';

const OverallResponseOverview = ({ trackerInstanceSectionId }) => {
  const {
    data,
    isLoading,
    refetch: refetchOverview
  } = useOverallResponseOverview({
    trackerInstanceSectionId
  });

  if (isLoading) return <LoadingSkeleton />;

  const {
    celebrate_data: {
      student_percentage: celebratePercentage,
      students: celebrateStudents
    },
    support_data: {
      student_percentage: supportPercentage,
      students: supportStudents
    },
    intervene_data: {
      student_percentage: intervenePercentage,
      students: interveneStudents
    }
  } = data;

  if (
    !isLoading &&
    celebratePercentage === '0.00' &&
    supportPercentage === '0.00' &&
    intervenePercentage === '0.00'
  ) {
    return <EmptyDataState />;
  }

  return (
    <>
      <ProgressBar
        celebratePercentage={celebratePercentage}
        supportPercentage={supportPercentage}
        intervenePercentage={intervenePercentage}
      />
      <MasteryLevelGroup
        title={CELEBRATE}
        icon={<CelebrateIcon />}
        students={celebrateStudents}
        percentage={celebratePercentage}
        color={CELEBRATE_COLOR}
        description={WORK_IS_COMPLETE_AND_CORRECT}
        refetchOverview={refetchOverview}
      />
      <MasteryLevelGroup
        title={SUPPORT}
        icon={<SupportIcon />}
        students={supportStudents}
        percentage={supportPercentage}
        color={SUPPORT_COLOR}
        description={GENERAL_CONCEPTUAL_UNDERSTANDING}
        refetchOverview={refetchOverview}
      />
      <MasteryLevelGroup
        title={INTERVENE}
        icon={<InterveneIcon />}
        students={interveneStudents}
        percentage={intervenePercentage}
        color={INTERVENE_COLOR}
        description={SIGNIFICANT_ERRORS_IN_WORK}
        refetchOverview={refetchOverview}
      />
    </>
  );
};

export default OverallResponseOverview;
