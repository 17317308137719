import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

const useStyles = ({ hasValue }) =>
  makeStyles(() => ({
    root: {
      '& .MuiTextField-root': {
        borderRadius: '0px',
        border: '0px',
        width: '100%',
        padding: '0px'
      },
      '& .MuiFilledInput-root': {
        borderRadius: '5px 5px 1px 1px',
        backgroundColor: colors.white,
        height: '100%',
        '&:hover': {
          backgroundColor: colors.grey6
        }
      },
      '& .MuiFilledInput-root.Mui-disabled': {
        backgroundColor: colors.grey6,
        '&:hover': {
          backgroundColor: colors.grey6
        }
      },
      '& .MuiFilledInput-underline:not(.Mui-disabled):before': {
        borderBottomColor: hasValue ? colors.blue4 : colors.grey4,
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid'
      },
      '& .MuiFilledInput-underline:not(.Mui-disabled):after': {
        borderBottomColor: hasValue ? colors.blue4 : colors.grey4,
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid'
      },
      '& .MuiFilledInput-input': {
        padding: '10px'
      },
      '& .MuiInputLabel-root': {
        transform: 'none',
        padding: '12px',
        color: colors.grey3,
        fontSize: '15px',
        lineHeight: 'initial',
        fontWeight: 400,
        letterSpacing: '0px'
      },
      '& .MuiInputBase-root': {
        position: 'absolute',
        width: '100%'
      }
    },
    greyDarkOnFocus: {
      '& .Mui-focused.MuiFilledInput-underline:after': {
        borderBottomColor: hasValue ? colors.blue4 : colors.greyDark
      },
      '& .MuiFilledInput-root': {
        '&:hover': {
          backgroundColor: colors.white
        }
      },
      '& .Mui-focused.MuiFilledInput-root': {
        backgroundColor: colors.grey6
      }
    }
  }));

const useSelectStyles = makeStyles({
  select: {
    // This is added so that the select arrow and text does not overlap
    maxWidth: 'calc(100% - 24px - 10px)' // 10px = padding, 24px = svg width
  }
});

const renderValue = (value) => (
  <Typography variant="B-Text-2" color={colors.grey1} noWrap>
    {value}
  </Typography>
);

const SelectFilter = ({
  placeholder,
  value,
  options,
  valueEqualToTheInitial = false,
  classes = {},
  MenuProps = {},
  greyDarkOnFocus = false,
  ...params
}) => {
  const hasValue =
    typeof value === 'object' ? !_.isEmpty(value) : !_.isNil(value);

  const styles = useStyles({ hasValue: hasValue && !valueEqualToTheInitial })();
  const selectStyles = useSelectStyles();

  return (
    <div
      className={cx(styles.root, {
        [styles.greyDarkOnFocus]: greyDarkOnFocus
      })}
    >
      <TextField
        select
        value={_.isEmpty(options) ? '' : value} // Fixes Material UI warning
        label={!hasValue && placeholder}
        variant="filled"
        classes={{ root: classes.root }}
        renderValue={renderValue}
        SelectProps={{
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            ...MenuProps
          },
          classes: selectStyles
        }}
        {...params}
      >
        {options.map((option) => (
          <MenuItem key={option.key || option.value} value={option.value}>
            {renderValue(option.label)}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default SelectFilter;
