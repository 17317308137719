import {
  TEACHERS_BULK_UPLOAD_ASSIGN_ASSIGNMENT_URL,
  TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL,
  TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL,
  TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL,
  TEACHERS_BULK_UPLOAD_SUCCESS_URL
} from 'config/urls';

export const BulkUploadStatus = {
  WAITING_FOR_AN_ASSIGNMENT: 'WAITING FOR AN ASSIGNMENT',
  WAITING_FOR_PAGE_CONFIRMATION: 'WAITING FOR PAGE CONFIRMATION',
  WAITING_FOR_NAME_REGION: 'WAITING FOR NAME REGION',
  WAITING_FOR_ASSIGNED_STUDENTS_CONFIRMATION:
    'WAITING FOR ASSIGNED STUDENTS CONFIRMATION',
  FINISHED: 'FINISHED'
};

export const BulkUploadStatusToUrl = {
  [BulkUploadStatus.WAITING_FOR_AN_ASSIGNMENT]:
    TEACHERS_BULK_UPLOAD_ASSIGN_ASSIGNMENT_URL,
  [BulkUploadStatus.WAITING_FOR_PAGE_CONFIRMATION]:
    TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL,
  [BulkUploadStatus.WAITING_FOR_NAME_REGION]:
    TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL,
  [BulkUploadStatus.WAITING_FOR_ASSIGNED_STUDENTS_CONFIRMATION]:
    TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL,
  [BulkUploadStatus.FINISHED]: TEACHERS_BULK_UPLOAD_SUCCESS_URL
};
