import React from 'react';
import { Link } from 'react-router-dom';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Skeleton from '@material-ui/lab/Skeleton';
import { TEACHERS_ASSIGNMENTS_RESULTS_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { reverse, stringifyParams } from 'utils/urls';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Placeholder = ({ count }) =>
  _.range(count).map((index) => (
    <div className={styles.section} key={index}>
      <Skeleton width={720} />
    </div>
  ));

const SectionLine = ({ name, submissionCount, trackerId, sectionId }) => (
  <Link
    className={styles.section}
    to={{
      pathname: reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
        trackerId: trackerId
      }),
      search: stringifyParams({
        section: sectionId
      })
    }}
  >
    <Typography variant="B-Text-1" color={colors.grey2} className={styles.name}>
      {name}
    </Typography>
    <Typography
      variant="S-TEXT-2"
      className={styles.submissionCount}
      color={colors.grey2}
    >
      {submissionCount} submissions
      <KeyboardArrowRightIcon size="small" className={styles.arrowRight} />
    </Typography>
  </Link>
);

SectionLine.Placeholder = Placeholder;

export default SectionLine;
