import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import { TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL } from 'config/urls';
import { ReactComponent as AddRoundedIcon } from 'images/add-grey.svg';
import _ from 'lodash';
import {
  bulkUploadAssignmentAssign,
  useAssignmentsNamesList,
  useBulkUploadDetail,
  useRecentlyCreatedAssignments
} from 'sdk';

import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import { useBulkUploadStatusRedirect } from 'pages/Teachers/BulkUpload/hooks';
import { SearchField } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { notifyErrors } from 'utils/notifications';
import { reverse, stringifyParams } from 'utils/urls';

import AssignmentDialogContext from 'components/AssignmentDialogContext';
import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AssignAssignment = ({ match }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const { setIsAssignmentDialogOpened } = useContext(AssignmentDialogContext);

  const { data: assignmentsNames } = useAssignmentsNamesList({
    data: { include_prior_years: true }
  });

  const { data: recentlyCreatedAssignmentsData } =
    useRecentlyCreatedAssignments({ params: { limit: 5 } });

  const recentlyCreatedAssignments = useMemo(
    () => _.get(recentlyCreatedAssignmentsData, 'results', []),
    [recentlyCreatedAssignmentsData]
  );

  const history = useHistory();

  const bulkUploadId = _.get(match, 'params.bulkUploadId');

  const { data: bulkUpload, isLoading: isFetchingBulkUpload } =
    useBulkUploadDetail({ bulkUploadId });
  useBulkUploadStatusRedirect({
    bulkUpload,
    isLoading: isFetchingBulkUpload
  });

  const handleNextClick = async ({ trackerId }) => {
    setShowLoader(true);

    const { success, errors } = await bulkUploadAssignmentAssign({
      bulkUploadId,
      data: { assignment: trackerId || selectedAssignment.tracker_id }
    });

    if (success) {
      history.push(
        reverse(TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL, {
          bulkUploadId
        })
      );
    } else {
      notifyErrors(errors);
    }

    setShowLoader(false);
  };

  return (
    <>
      <Layout
        currentStepIndex={1}
        showLoader={showLoader}
        nextButtonAction={handleNextClick}
        isNextButtonDisabled={_.isNil(selectedAssignment) || showLoader}
      >
        <div className={styles.container}>
          <Typography variant="H-TEXT-1" className={styles.title}>
            What Assignment is this?
          </Typography>
          <SearchField
            value={selectedAssignment}
            classes={{ root: styles.search }}
            options={assignmentsNames || []}
            getOptionLabel={(assignment) => assignment.name}
            onChange={(event, value) => setSelectedAssignment(value)}
            noOptionsText="No Assignments"
            placeholder="Search your assignments"
          />
          <Typography variant="B-TEXT-3" className={styles.divider}>
            or
          </Typography>

          <ButtonGroup
            classes={{ root: styles.buttonGroup }}
            disableElevation
            onClick={() => {
              history.replace({
                pathname: history.pathname,
                search: stringifyParams({ bulkUploadId, is_bulk_upload: true })
              });
              setIsAssignmentDialogOpened(true);
            }}
          >
            <Button
              classes={{ root: styles.addIconButton }}
              color={colors.grey4}
              noTypography
            >
              <AddRoundedIcon />
            </Button>
            <Button
              classes={{ root: styles.createButton, label: styles.label }}
            >
              Create assignment
            </Button>
          </ButtonGroup>

          {!_.isEmpty(recentlyCreatedAssignments) && (
            <div className={styles.recentAssignments}>
              <Typography variant="S-TEXT-2" color={colors.grey3}>
                Recently assigned
              </Typography>
              {recentlyCreatedAssignments.map((assignment) => (
                <div
                  key={assignment.id}
                  className={styles.assignmentItem}
                  onClick={() => handleNextClick({ trackerId: assignment.id })}
                >
                  <Typography variant="B-TEXT-3" color={colors.blue3}>
                    {assignment.name}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default AssignAssignment;
