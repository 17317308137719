import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import OverallResponseOverview from 'pages/Teachers/InsightsV2/components/OverallResponseOverview';
import { OVERALL_RESPONSE_TAB } from 'pages/Teachers/InsightsV2/constants';
import { parseParams } from 'utils/urls';

import TabSwitcher from './components/TabSwitcher';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const InsightsV2 = () => {
  const history = useHistory();
  const { assignmentName, sectionName } = parseParams(history.location.search);
  const { trackerInstanceSectionId } = useParams();

  const [activeTab, setActiveTab] = useState(OVERALL_RESPONSE_TAB);

  return (
    <div className={styles.container}>
      <Typography variant="H-TEXT-2" className={styles.headerText}>
        {assignmentName} - {sectionName}
      </Typography>
      <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
      <Typography variant="H-TEXT-2" className={styles.headerText}>
        We&apos;ve grouped your students by their performance—those who
        excelled, those who need a bit more guidance, and those who require
        targeted intervention. Follow the steps in each section to provide the
        right level of support, from celebrating successes to re-teaching
        concepts.
      </Typography>

      <div className={styles.detailContainer}>
        {activeTab === OVERALL_RESPONSE_TAB && (
          <OverallResponseOverview
            trackerInstanceSectionId={trackerInstanceSectionId}
          />
        )}
        {/* {activeTab == MISCONCEPTIONS_TAB && <MisconceptionsOverview/>} */}
      </div>
    </div>
  );
};

export default InsightsV2;
