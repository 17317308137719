import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  CLEVER_SSO_LOGIN_SOURCE,
  GOOGLE_SSO_LOGIN_SOURCE
} from 'config/constants';
import {
  PATHWAYS_CHATBOT_URL,
  PATHWAYS_COMMUNITY_URL,
  PATHWAYS_DAILY_PROGRESS_URL,
  PATHWAYS_GATHERING_RESULTS_URL,
  PATHWAYS_INSIGHTS_URL,
  PATHWAYS_PROFILE_JOURNEY_URL,
  PATHWAYS_PROFILE_PERSONAL_GOALS_URL,
  PATHWAYS_PROGRESS_URL,
  PATHWAYS_RESULTS_URL,
  PATHWAYS_START_URL,
  PATHWAYS_TODAY_LESSON_URL,
  TEACHERS_PERMISSION_DENIED_URL
} from 'config/urls';
import { get, includes, isUndefined } from 'lodash';
import mixpanel from 'mixpanel-browser';

import { TeacherPageLayout, UsageDialog } from 'pages/Teachers/shared';
import { parseUsage } from 'pages/Teachers/shared/utils';
import { shutdownIntercomWidget } from 'utils/integrations/intercom';
import { parseParams } from 'utils/urls';

import PathwaysChatbot from './components/ChatBot/index';
import GatheringResults from './components/GatheringResults';
import Insights from './components/Insights';
import PathwaysCommunity from './components/PathwaysCommunity';
import PathwaysDailyProgress from './components/PathwaysDailyProgress';
import PathwaysProfileJourney from './components/PathwaysProfileJourney';
import PathwaysProfilePersonalGoals from './components/PathwaysProfilePersonalGoals';
import PathwaysProgress from './components/PathwaysProgress';
import PathwaysResults from './components/PathwaysResults';
import PathwaysStart from './components/PathwaysStart';
import PathwaysTodayLesson from './components/PathwaysTodayLesson';
import TeacherRequired from 'components/TeacherRequired';
import UsageContext from 'components/UsageContext';
import UserContext from 'components/UserContext';

const useUsageDialog = () => {
  const { user } = useContext(UserContext);
  const usage = parseUsage(user.usage);

  const [usageDialog, setUsageDialog] = useState(
    (usage && !usage.hasAccess) || false
  );

  const openDialog = () => {
    const shouldOpen = (!usageDialog && usage && !usage.hasAccess) || false;

    if (shouldOpen) {
      setUsageDialog(true);
    }
  };
  const closeDialog = () => {
    setUsageDialog(false);
  };

  return { usage, dialog: usageDialog, openDialog, closeDialog };
};

const Pathways = () => {
  const history = useHistory();

  const { dialog, openDialog, closeDialog, usage } = useUsageDialog();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const { user } = useContext(UserContext);
  const isPathwaysUser = user.is_pathways_user;

  useEffect(() => {
    if (isPathwaysUser) shutdownIntercomWidget();
  }, [isPathwaysUser]);

  const searchParams = useMemo(
    () => parseParams(history.location.search),
    [history.location.search]
  );
  const loginSource = useMemo(
    () => get(searchParams, 'source', undefined),
    [searchParams]
  );

  useEffect(() => {
    if (
      !includes([GOOGLE_SSO_LOGIN_SOURCE, CLEVER_SSO_LOGIN_SOURCE], loginSource)
    )
      return;
    if (isUndefined(process.env.REACT_APP_MIXPANEL_KEY)) return;

    mixpanel.identify(String(user.id));
  }, [user, loginSource]);

  if (!isPathwaysUser) {
    return <Redirect to={TEACHERS_PERMISSION_DENIED_URL} />;
  }

  return (
    <UsageContext.Provider value={{ usage, openUsageDialog: openDialog }}>
      <TeacherPageLayout
        setSelectedOrganization={setSelectedOrganization}
        setSelectedSchool={setSelectedSchool}
        selectedOrganization={selectedOrganization}
      >
        <Switch>
          <Route path={PATHWAYS_START_URL} component={PathwaysStart} />
          <Route
            path={PATHWAYS_PROFILE_JOURNEY_URL}
            component={PathwaysProfileJourney}
          />
          <Route
            path={PATHWAYS_PROFILE_PERSONAL_GOALS_URL}
            component={PathwaysProfilePersonalGoals}
          />
          <Route
            path={PATHWAYS_GATHERING_RESULTS_URL}
            component={GatheringResults}
          />
          <Route
            path={PATHWAYS_DAILY_PROGRESS_URL}
            component={PathwaysDailyProgress}
          />
          <Route path={PATHWAYS_CHATBOT_URL} component={PathwaysChatbot} />
          <Route path={PATHWAYS_RESULTS_URL} component={PathwaysResults} />
          <Route
            path={PATHWAYS_TODAY_LESSON_URL}
            component={PathwaysTodayLesson}
          />
          <Route path={PATHWAYS_PROGRESS_URL} component={PathwaysProgress} />
          <Route path={PATHWAYS_COMMUNITY_URL} component={PathwaysCommunity} />
          <Route
            path={PATHWAYS_INSIGHTS_URL}
            render={(props) => (
              <Insights
                {...props}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
                selectedSchool={selectedSchool}
                setSelectedSchool={setSelectedSchool}
              />
            )}
          />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        {dialog && <UsageDialog usage={usage} onClose={closeDialog} />}
      </TeacherPageLayout>
    </UsageContext.Provider>
  );
};

export default TeacherRequired(Pathways);
