import React, { useState } from 'react';

import MasteryLevelTable from 'pages/Teachers/InsightsV2/components/MasteryLevelTable';
import StudentSummaryDialog from 'pages/Teachers/InsightsV2/components/StudentSummaryDialog';
import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MasteryLevelDetail = ({ students, refetchOverview }) => {
  const [summaryDialogIsOpen, setSummaryDialogIsOpen] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [workTagImageId, setWorkTagImageId] = useState(null);

  return (
    <div className={styles.details}>
      <div className={styles.students}>
        <div className={styles.studentsContainer}>
          {students.map(({ name, work_tag_image_id }) => (
            <div
              key={name}
              className={styles.studentNameContainer}
              onClick={() => {
                setStudentName(name);
                setWorkTagImageId(work_tag_image_id);
                setSummaryDialogIsOpen(true);
              }}
            >
              <Typography
                variant="B-Text-3"
                className={styles.studentName}
                color={colors.blue10}
              >
                {name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <MasteryLevelTable />
      {summaryDialogIsOpen && (
        <StudentSummaryDialog
          studentName={studentName}
          workTagImageId={workTagImageId}
          refetchOverview={refetchOverview}
          isSummaryDialogIsOpen={summaryDialogIsOpen}
          onClose={() => {
            setSummaryDialogIsOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default MasteryLevelDetail;
