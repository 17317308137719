import React, { useState } from 'react';

import MasteryLevelDetail from 'pages/Teachers/InsightsV2/components/MasteryLevelDetail';

import Typography from 'components/Typography';

import { ReactComponent as ArrowDownIcon } from './assets/arrow-down-icon.svg';
import { ReactComponent as ArrowUpIcon } from './assets/arrow-up-icon.svg';
import styles from './styles.module.scss';

const MasteryLevelGroup = ({
  title,
  icon,
  students,
  percentage,
  color,
  description,
  refetchOverview
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () =>
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer} onClick={toggleExpanded}>
        <div className={styles.summary}>
          <div className={styles.titleContainer}>
            {icon}
            <Typography variant="H-TEXT-2">
              {title} - {students.length} ({(percentage * 100).toFixed(0)}%)
            </Typography>
          </div>
          <div className={styles.titleContainer}>
            <div
              className={styles.rectangle}
              style={{ backgroundColor: color }}
            ></div>
            <Typography
              variant="B-TEXT-4"
              color={color}
              className={styles.description}
            >
              {description}
            </Typography>
          </div>
        </div>
        {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      {isExpanded && (
        <MasteryLevelDetail
          students={students}
          refetchOverview={refetchOverview}
        />
      )}
    </div>
  );
};

export default MasteryLevelGroup;
