import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import classnames from 'classnames';
import { TEACHERS_CAPTURE_URL } from 'config/urls';
import _ from 'lodash';

import ScrollableStudentWorkList from 'pages/Teachers/Assignments/Results/components/ScrollableStudentWorkList';
import StudentWorkCard from 'pages/Teachers/Assignments/Results/components/StudentWorkCard';

import Button from 'components/Button';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StudentWorkList = ({
  studentWorkList,
  filters,
  buildAssignmentFeedbackLinkObject,
  showScores,
  multiSelectMode,
  addComment,
  changeTaskStatus,
  pulseEffectTriggered,
  onPulseEffectEnd,
  onAddSticker,
  toggleSelectStudentWorkItem,
  width,
  activeTasksIds,
  storeActiveTaskId,
  removeActiveTaskId,
  setSelectedWorksSelectedChildren,
  refetchStudentWork
}) => {
  const visibleTaskIds = useMemo(
    () => _.map(filters.tasks, 'id'),
    [filters.tasks]
  );
  const showAll = useMemo(
    () => filters.show.value === 'all_students',
    [filters.show]
  );
  const showAllStudentsAllTasks = useMemo(
    () => filters.show.value === 'all_students_all_tasks',
    [filters.show]
  );
  const showAllSubmittedAllTasks = useMemo(
    () => filters.show.value === 'all_submitted_all_tasks',
    [filters.show]
  );

  const hasStudentWork = !_.isEmpty(studentWorkList);

  const lgScreen = isWidthUp('lg', width);

  const wrapperClassName = classnames({
    [styles.wrapper]: lgScreen,
    [styles.smallwrapper]: !lgScreen
  });

  const onAnimationEnd = () => {
    if (pulseEffectTriggered) {
      onPulseEffectEnd();
    }
  };

  return (
    <Box
      className={classnames(wrapperClassName, {
        [styles.allTasksWrapper]:
          showAllStudentsAllTasks || showAllSubmittedAllTasks
      })}
      onAnimationEnd={onAnimationEnd}
    >
      {!hasStudentWork && !showAll && (
        <NoResultsPlaceholder
          text="to get started"
          button={
            <Button
              color="blue"
              className={styles.captureWorkButton}
              noTypography
              href={TEACHERS_CAPTURE_URL}
            >
              <SvgIcon
                className={styles.cameraIcon}
                component={CameraAltIcon}
              />
              <Typography variant="H-TEXT-3">capture work</Typography>
            </Button>
          }
        />
      )}
      {(hasStudentWork || showAll) &&
        !showAllStudentsAllTasks &&
        !showAllSubmittedAllTasks && (
          <div className={styles.studentCardsContainer}>
            {studentWorkList.map(({ work, student, selected }) => (
              <StudentWorkCard
                key={student.id}
                student={student}
                tasksData={work}
                selected={selected}
                showScores={showScores}
                addComment={addComment}
                multiSelectMode={multiSelectMode}
                pulseEffectTriggered={pulseEffectTriggered}
                toggleSelectStudentWorkItem={toggleSelectStudentWorkItem}
                buildAssignmentFeedbackLinkObject={
                  buildAssignmentFeedbackLinkObject
                }
                activeTasksIds={activeTasksIds}
                storeActiveTaskId={storeActiveTaskId}
                removeActiveTaskId={removeActiveTaskId}
                setSelectedWorksSelectedChildren={
                  setSelectedWorksSelectedChildren
                }
                refetchStudentWork={refetchStudentWork}
              />
            ))}
          </div>
        )}
      {hasStudentWork &&
        (showAllStudentsAllTasks || showAllSubmittedAllTasks) && (
          <ScrollableStudentWorkList
            studentWorkList={studentWorkList}
            buildAssignmentFeedbackLinkObject={
              buildAssignmentFeedbackLinkObject
            }
            showScores={showScores}
            addComment={addComment}
            visibleTaskIds={visibleTaskIds}
            changeTaskStatus={changeTaskStatus}
            multiSelectMode={multiSelectMode}
            onAddSticker={onAddSticker}
            toggleSelectStudentWorkItem={toggleSelectStudentWorkItem}
            storeActiveTaskId={storeActiveTaskId}
            removeActiveTaskId={removeActiveTaskId}
            activeTasksIds={activeTasksIds}
            setSelectedWorksSelectedChildren={setSelectedWorksSelectedChildren}
            refetchStudentWork={refetchStudentWork}
          />
        )}
    </Box>
  );
};

export default withWidth()(StudentWorkList);
