export const OVERALL_RESPONSE_TAB = 'Overall Responses';
export const MISCONCEPTIONS_TAB = 'Misconceptions';

export const CELEBRATE = 'Celebrate';
export const WORK_IS_COMPLETE_AND_CORRECT = 'Work is complete and correct';
export const CELEBRATE_COLOR = '#F05B94';

export const SUPPORT = 'Support';
export const GENERAL_CONCEPTUAL_UNDERSTANDING =
  'General conceptual understanding but work may be insufficient or contain some errors';
export const SUPPORT_COLOR = '#FD81BA';

export const INTERVENE = 'Intervene';
export const SIGNIFICANT_ERRORS_IN_WORK = 'Significant errors in work';
export const INTERVENE_COLOR = '#FFB0D6';

export const MISCONCEPTION_OPTIONS = [
  'Computation Error (e.g., mental math errors)',
  'Precision Error (e.g., wrong units, wrong place value)',
  'Conceptual Misunderstanding (could have multiple)',
  'Representational Error (e.g., not able to draw accurate model)',
  'Insufficient Explanation (e.g., correct answer, inadequate explanation / model)',
  "Incomplete (Didn't finish / didn't answer the question)",
  "Did Not Answer Specific Question (e.g., didn't follow directions)"
];
