import React, { useMemo, useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { useFiltersData, useSectionRubrics } from 'sdk';

import InfoRubricDialog from 'pages/Teachers/StrategiesOverview/components/InfoRubricDialog';
import RubricAccordion from 'pages/Teachers/StrategiesOverview/components/RubricAccordion';
import { getPlasUserStartegyName } from 'pages/Teachers/StrategiesOverview/utils';

import styles from './styles.module.scss';

const RubricsBreakdown = ({
  selectedSection,
  selectedGrade,
  selectedUnit,
  selectedLesson,
  selectedStrategy,
  selectedRubric,
  setSelectedRubric,
  filterSortParams,
  isPlasUser
}) => {
  const { data: filterData, isFiltersLoading } = useFiltersData({
    sectionId: selectedSection.id
  });

  const filterFlags = {
    is_multi_lingual_learner: filterData?.is_mll_filter_enabled,
    is_on_individualized_education_plan: filterData?.is_iep_filter_enabled
  };

  const conditionalFilterParams = Object.fromEntries(
    Object.entries(filterSortParams).filter(
      ([key, _]) => !(key in filterFlags) || filterFlags[key]
    )
  );
  const { data, isLoading } = useSectionRubrics({
    sectionId: selectedSection.id,
    params: {
      grade: selectedGrade.name,
      unit: selectedUnit.name,
      lesson: selectedLesson.name,
      strategy_label_name: selectedStrategy.strategy_name,
      ...conditionalFilterParams
    }
  });

  const rubrics = useMemo(() => {
    if (isPlasUser && data) {
      return data.map((rubric) => {
        return {
          ...rubric,
          strategy_answer: getPlasUserStartegyName({
            strategy: rubric.strategy_answer
          })
        };
      });
    }
    return data;
  }, [data, isPlasUser]);

  const [isOpen, setIsOpen] = useState(false);
  const [dialogRubrics, setDialogRubrics] = useState({});

  return (
    <>
      {isOpen && (
        <InfoRubricDialog
          rubrics={dialogRubrics}
          onClose={() => setIsOpen(false)}
        />
      )}
      {isLoading && (
        <>
          <div className={styles.loadingState}>
            <Skeleton variant="rect" height={50} />
          </div>
          <div className={styles.loadingState}>
            <Skeleton variant="rect" height={50} />
          </div>
          <div className={styles.loadingState}>
            <Skeleton variant="rect" height={50} />
          </div>
        </>
      )}
      {!isLoading &&
        !isFiltersLoading &&
        rubrics.map(
          ({ strategy_answer, student_count, student_work_list }, index) => (
            <RubricAccordion
              strategy_answer={strategy_answer}
              student_work_list={student_work_list}
              key={`rubric-accordion-${index}`}
              setIsOpen={setIsOpen}
              setDialogRubrics={setDialogRubrics}
              selectedRubric={selectedRubric}
              setSelectedRubric={setSelectedRubric}
              index={index}
              studentCount={student_count}
            />
          )
        )}
    </>
  );
};

export default RubricsBreakdown;
