import React from 'react';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './styles.module.scss';

const LoadingSkeleton = () => {
  return (
    <Box className={styles.container}>
      {/* Horizontal Skeletons */}
      <Box className={styles.progressBar}>
        <Skeleton variant="rectangular" height={30} width="100%" />
        <Skeleton variant="rectangular" height={30} width="100%" />
        <Skeleton variant="rectangular" height={30} width="100%" />
      </Box>

      {/* Vertical Skeletons */}
      <Box className={styles.content}>
        <Skeleton variant="rectangular" height={100} width="100%" />
        <Skeleton variant="rectangular" height={100} width="100%" />
        <Skeleton variant="rectangular" height={100} width="100%" />
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;
