import React from 'react';

import { useWorkTagImageMasteryLevelData } from 'sdk';

import LoadingSkeleton from 'pages/Teachers/InsightsV2/components/LoadingSkeleton';
import MisconceptionMasteryLevel from 'pages/Teachers/InsightsV2/components/MisconceptionMasteryLevel';
import OverallResponseMasteryLevel from 'pages/Teachers/InsightsV2/components/OverallResponseMasteryLevel';

import Dialog from 'components/Dialog';
import Image from 'components/Image';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StudentSummaryDialog = ({
  studentName,
  workTagImageId,
  refetchOverview,
  isSummaryDialogIsOpen,
  onClose
}) => {
  const {
    data,
    isLoading,
    refetch: refetchMasteryLevel
  } = useWorkTagImageMasteryLevelData({
    workTagImageId
  });

  return (
    <div className={styles.dialogContainer}>
      <Dialog
        open={isSummaryDialogIsOpen}
        onClose={onClose}
        className={styles.dialog}
        classes={{ paper: styles.paperRoot }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography className={styles.studentName} variant="H-TEXT-1">
          {studentName}
        </Typography>
        <div className={styles.dialogContent}>
          <div className={styles.leftCard}>
            <div className={styles.imageContainer}>
              {!isLoading && (
                <Image src={data.work_tag_image_url} className={styles.image} />
              )}
            </div>
          </div>
          <div className={styles.rightCard}>
            {isLoading && <LoadingSkeleton />}
            {!isLoading && (
              <>
                <OverallResponseMasteryLevel
                  workTagImageId={workTagImageId}
                  masteryLevel={data.overall_response_mastery_level}
                  refetchOverview={refetchOverview}
                />
                <MisconceptionMasteryLevel
                  workTagImageId={workTagImageId}
                  masteryLevel={data.misconception_mastery_level}
                  refetchMasteryLevel={refetchMasteryLevel}
                />
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StudentSummaryDialog;
