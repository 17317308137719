import React, { useMemo, useState } from 'react';

import { Skeleton } from '@material-ui/lab';
import { useSectionStrategies } from 'sdk';

import StrategyDoughnutChart from 'pages/Pathways/components/Insights/components/StrategyDoughnutChart';
import RubricsBreakdown from 'pages/Teachers/StrategiesOverview/components/RubricsBreakdown';

import TabbedChartSelection from './../TabbedChartSelection/index';
import styles from './styles.module.scss';

const AccordionContainer = ({
  selectedSection,
  selectedGrade,
  selectedUnit,
  selectedLesson,
  filterSortParams
}) => {
  const [selectedStrategyIndex, setSelectedStrategyIndex] = useState(0);
  const [selectedRubric, setSelectedRubric] = useState('');
  const { data: strategiesData, isLoading: isLoadingStrategies } =
    useSectionStrategies({
      sectionId: selectedSection?.id,
      params: {
        grade: selectedGrade?.name,
        unit: selectedUnit?.name,
        lesson: selectedLesson?.name
      }
    });

  const strategies = useMemo(() => {
    if (!strategiesData) return [];
    return strategiesData['strategies'];
  }, [strategiesData]);

  const selectedStrategy = useMemo(() => {
    if (selectedStrategyIndex === undefined) {
      return null;
    }
    return strategies[selectedStrategyIndex];
  }, [selectedStrategyIndex, strategies]);

  const assignmentSelected =
    selectedSection && selectedUnit && selectedLesson && selectedGrade;

  if (isLoadingStrategies) {
    return <Skeleton variant="rect" height={52} />;
  }

  return (
    <div className={styles.accordionContainer}>
      <TabbedChartSelection
        tabs={strategies.map((strategy) => ({
          name: strategy.strategy_name,
          data: strategy
        }))}
        onChange={setSelectedStrategyIndex}
      />
      {selectedStrategy && (
        <StrategyDoughnutChart
          selectedStrategy={selectedStrategy}
          onRubricSelected={setSelectedRubric}
        />
      )}
      {assignmentSelected && selectedStrategy && (
        <RubricsBreakdown
          selectedSection={selectedSection}
          selectedGrade={selectedGrade}
          selectedUnit={selectedUnit}
          selectedLesson={selectedLesson}
          selectedStrategy={selectedStrategy}
          selectedRubric={selectedRubric}
          setSelectedRubric={setSelectedRubric}
          filterSortParams={filterSortParams}
        />
      )}
    </div>
  );
};

export default AccordionContainer;
