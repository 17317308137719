import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { throttle } from 'lodash';

import {
  determineTooltipText,
  matchColortoIcon,
  remappedRubricsToChecks
} from 'pages/Teachers/StrategiesOverview/utils';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const mapMasteryLevelToText = ({ masteryLevel }) => {
  switch (masteryLevel) {
    case 0:
      return 'Got it';
    case 1:
      return 'Almost there';
    case 2:
      return 'Not yet';
    default:
      return 'N/A';
  }
};

const StrategyRubrics = ({ strategiesBreakdown, user }) => {
  const trackMixpanelEventOnIconsHover = ({
    strategyName,
    rubricItemText,
    masteryLevel
  }) => {
    const tooltipText = mapMasteryLevelToText({ masteryLevel });

    trackMixpanelEvent(user.id, 'Teacher hovered tooltip info icon.', {
      rubric: rubricItemText,
      strategy: strategyName,
      tooltipText: tooltipText
    });
  };

  let trackMixpanelEventOnIconsHoverThrottled = throttle(
    ({ strategyName, rubricItemText, masteryLevel }) =>
      trackMixpanelEventOnIconsHover({
        strategyName,
        rubricItemText,
        masteryLevel
      }),
    1000,
    { leading: true, trailing: false }
  );

  return (
    <>
      {strategiesBreakdown.map(({ strategy, rubrics }, strategyIndex) => (
        <div
          key={`summaryStrategy-${strategyIndex}`}
          className={styles.strategyContainer}
        >
          <Typography
            variant="H-TEXT-3"
            component="h5"
            className={styles.summaryData}
          >
            {strategyIndex + 1}. {strategy}
          </Typography>
          {rubrics.map(({ name, value }, rubricIndex) => (
            <div
              key={`summaryRubric-${rubricIndex}`}
              className={styles.innerData}
            >
              <div
                onClick={() => {
                  trackMixpanelEvent(
                    user.id,
                    '[StrategiesOverview] Teacher attempted to click on rubric icon.'
                  );
                }}
              >
                <Tooltip
                  classes={{
                    tooltip: styles.tooltipInfo,
                    arrow: styles.tooltipArrow
                  }}
                  arrow={true}
                  title={determineTooltipText({
                    iconType: remappedRubricsToChecks({
                      masteryLevel: value,
                      color: matchColortoIcon({ valueIcon: value })
                    })
                  })}
                >
                  {remappedRubricsToChecks({
                    masteryLevel: value,
                    color: matchColortoIcon({ valueIcon: value }),
                    onMouseEnter: () =>
                      trackMixpanelEventOnIconsHoverThrottled({
                        strategyName: strategy,
                        rubricItemText: name,
                        masteryLevel: value
                      })
                  })}
                </Tooltip>
              </div>{' '}
              <Typography variant="S-TEXT-1" className={styles.lineText}>
                {name}
              </Typography>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default StrategyRubrics;
