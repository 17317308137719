import React from 'react';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const ProgressTab = ({ label, description, percentage, hoverPopupColor }) => {
  const segmentClass = `${styles.progressSegment} ${
    styles[label.toLowerCase()]
  }`;

  return (
    <div
      className={styles.segmentContainer}
      style={{ width: `${percentage}%` }}
    >
      <div className={styles.name}>
        <Typography variant="H-TEXT-2">
          {percentage}% ({label})
        </Typography>
      </div>

      <div className={styles.hoverableSegment}>
        <div
          className={styles.hoverPopup}
          style={{ backgroundColor: hoverPopupColor }}
        >
          {label} ({percentage}%) - {description}
        </div>
        <div className={segmentClass}></div>
      </div>
    </div>
  );
};

export default ProgressTab;
