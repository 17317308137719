import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import SyncIcon from '@material-ui/icons/Sync';
import { TEACHERS_BULK_UPLOAD_SUCCESS_URL } from 'config/urls';
import _ from 'lodash';
import {
  finishBulkUpload,
  useBulkUploadDetail,
  useBulkUploadGroups
} from 'sdk';

import GroupsList from 'pages/Teachers/BulkUpload/StudentsConfirmation/components/GroupsList';
import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import { useBulkUploadStatusRedirect } from 'pages/Teachers/BulkUpload/hooks';
import colors from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import ConfirmDialog from 'components/ConfirmDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StudentsConfirmation = ({ match }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [hasUnmatchedImages, setHasUnmatchedImages] = useState(false);

  const history = useHistory();

  const {
    isOpened: isConfirmDialogOpened,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog
  } = useDialog();

  const bulkUploadId = _.get(match, 'params.bulkUploadId');

  const { data: matchedBulkUploadImageGroups } = useBulkUploadGroups({
    params: { matched: true },
    bulkUploadId
  });

  const {
    data: bulkUpload,
    isLoading: isFetchingBulkUpload,
    refetch: refetchBulkUpload
  } = useBulkUploadDetail({ bulkUploadId });

  useBulkUploadStatusRedirect({
    bulkUpload,
    isLoading: isFetchingBulkUpload
  });

  const bulkUploadHasUnprocessedImages = _.get(
    bulkUpload,
    'has_unprocessed_images',
    true
  );
  const allBulkUploadImagesHaveBeenCreated = _.get(
    bulkUpload,
    'bulk_upload_images_have_been_successfully_created',
    false
  );
  const bulkUploadHasUngroupedImages = _.get(
    bulkUpload,
    'has_ungrouped_images',
    true
  );

  const shouldPoll =
    bulkUploadHasUnprocessedImages ||
    !allBulkUploadImagesHaveBeenCreated ||
    bulkUploadHasUngroupedImages;

  useEffect(() => {
    if (shouldPoll) {
      const interval = setInterval(() => refetchBulkUpload(), 2000);

      return () => clearInterval(interval);
    }
  }, [shouldPoll, refetchBulkUpload]);

  const handleBulkUploadFinish = async () => {
    if (shouldPoll) {
      return;
    }

    setShowLoader(true);

    const { success, errors } = await finishBulkUpload({
      bulkUploadId
    });

    if (success) {
      history.push(
        reverse(TEACHERS_BULK_UPLOAD_SUCCESS_URL, {
          bulkUploadId
        })
      );
    } else {
      notifyErrors(errors);
    }

    setShowLoader(false);
  };

  const handleNextClick = () => {
    if (hasUnmatchedImages) {
      openConfirmDialog();
    } else {
      handleBulkUploadFinish();
    }
  };

  const assignmentId = bulkUpload?.assignment?.id;

  return (
    <Layout
      currentStepIndex={2}
      nextButtonAction={handleNextClick}
      isNextButtonDisabled={
        showLoader || shouldPoll || matchedBulkUploadImageGroups?.length === 0
      }
      nextButtonTooltip={
        matchedBulkUploadImageGroups?.length === 0
          ? 'Match at least 1 student to continue'
          : undefined
      }
      showLoader={showLoader}
      className={styles.wrapper}
    >
      {shouldPoll && (
        <div className={styles.matchingStudents}>
          <SyncIcon className={styles.syncIcon} />
          <Typography variant="H-TEXT-1" className={styles.matchingHeader}>
            Matching students
          </Typography>
        </div>
      )}

      <GroupsList
        bulkUploadId={bulkUploadId}
        assignmentId={assignmentId}
        setHasUnmatchedImages={setHasUnmatchedImages}
        handleNextClick={handleNextClick}
        shouldPoll={shouldPoll}
      />
      {isConfirmDialogOpened && (
        <ConfirmDialog
          onClose={closeConfirmDialog}
          onConfirm={() => {
            closeConfirmDialog();
            handleBulkUploadFinish();
          }}
        >
          <div>
            <Typography variant="B-Text-1" color={colors.grey1} align="center">
              All unmatched images will be permanently lost.
            </Typography>
          </div>
        </ConfirmDialog>
      )}
    </Layout>
  );
};

export default StudentsConfirmation;
