// Google
export const GOOGLE_LOGIN_REDIRECT_URI =
  'api/v1/auth/login/google-oauth2/callback/';
export const GOOGLE_SIGN_UP_REDIRECT_URL =
  'api/v1/integrations/google/sign-up/callback/';
export const GOOGLE_CLASSROOM_LOGIN_REDIRECT_URI =
  'api/v1/integrations/google/classroom/callback/';
export const GOOGLE_CLASSROOM_SCHOOL_SIGN_UP_REDIRECT_URI =
  'api/v1/integrations/google/classroom/school-sign-up/callback/';

export const GOOGLE_SYNC_ID = 'googleSyncId';
export const GOOGLE_SSO_LOGIN_SOURCE = 'google-sso';

// Clever
export const CLEVER_LOGIN_REDIRECT_URI =
  'api/v1/auth/login/clever-oauth2/callback/';
export const CLEVER_SSO_TEACHER_REDIRECT_URI =
  'api/v1/clever/sso/teacher-rollout/callback/';

export const CLEVER_SYNC_ID = 'cleverSyncId';
export const CLEVER_SSO_LOGIN_SOURCE = 'clever-sso';
