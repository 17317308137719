import React from 'react';

import ProgressTab from 'pages/Teachers/InsightsV2/components/ProgressTab';
import {
  CELEBRATE,
  CELEBRATE_COLOR,
  GENERAL_CONCEPTUAL_UNDERSTANDING,
  INTERVENE,
  INTERVENE_COLOR,
  SIGNIFICANT_ERRORS_IN_WORK,
  SUPPORT,
  SUPPORT_COLOR,
  WORK_IS_COMPLETE_AND_CORRECT
} from 'pages/Teachers/InsightsV2/constants';

import styles from './styles.module.scss';

const ProgressBar = ({
  celebratePercentage,
  supportPercentage,
  intervenePercentage
}) => {
  const celebratePercentageFixed = (celebratePercentage * 100).toFixed(0);
  const supportPercentageFixed = (supportPercentage * 100).toFixed(0);
  const intervenePercentageFixed = (intervenePercentage * 100).toFixed(0);

  const areAllZero =
    celebratePercentageFixed === '0' &&
    supportPercentageFixed === '0' &&
    intervenePercentageFixed === '0';
  const defaultPercentage = areAllZero ? '33' : null;

  return (
    <div className={styles.progressBars}>
      {(celebratePercentageFixed !== '0' || areAllZero) && (
        <ProgressTab
          label={CELEBRATE}
          description={WORK_IS_COMPLETE_AND_CORRECT}
          percentage={defaultPercentage || celebratePercentageFixed}
          hoverPopupColor={CELEBRATE_COLOR}
        />
      )}
      {(supportPercentageFixed !== '0' || areAllZero) && (
        <ProgressTab
          label={SUPPORT}
          description={GENERAL_CONCEPTUAL_UNDERSTANDING}
          percentage={defaultPercentage || supportPercentageFixed}
          hoverPopupColor={SUPPORT_COLOR}
        />
      )}
      {(intervenePercentageFixed !== '0' || areAllZero) && (
        <ProgressTab
          label={INTERVENE}
          description={SIGNIFICANT_ERRORS_IN_WORK}
          percentage={defaultPercentage || intervenePercentageFixed}
          hoverPopupColor={INTERVENE_COLOR}
        />
      )}
    </div>
  );
};

export default ProgressBar;
