import React from 'react';

import cx from 'classnames';

import Typography from 'components/Typography';

import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as UploadIcon } from './assets/upload.svg';
import styles from './styles.module.scss';

const STEPS = [
  { name: 'Upload Scan', icon: UploadIcon },
  { name: 'Set Assignment', icon: SearchIcon },
  { name: 'Match Students', icon: CheckIcon }
];

const Stepper = ({ currentStepIndex, className }) => (
  <div className={cx(styles.container, className)}>
    {STEPS.map(({ name, icon: Icon }, index) => {
      const isCompleted = currentStepIndex > index;
      const isActive = currentStepIndex === index;
      const shouldHaveConnector = index > 0;

      return (
        <div
          key={name}
          className={cx(
            styles.step,
            {
              [styles.completed]: isCompleted
            },
            {
              [styles.active]: isActive
            }
          )}
        >
          {shouldHaveConnector && <div className={styles.prevStepConnector} />}
          <div className={styles.iconContainer}>
            <Icon />
          </div>
          <Typography variant="B-Text-3" className={styles.name}>
            {name}
          </Typography>
        </div>
      );
    })}
  </div>
);

export default Stepper;
