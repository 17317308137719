import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const TooltipTitle = () => (
  <Typography variant="B-Text-2">
    Leverage AI for Illustrative Mathematics Cool Downs
  </Typography>
);

const AssignmentCreateSelectFlow = ({
  setIsFlowSelected,
  setIsCreateNewSelected,
  setIsCreateTemplateSelected
}) => {
  const onCreateNewAssignmentFlowSelect = () => {
    setIsFlowSelected(true);
    setIsCreateTemplateSelected(false);
    setIsCreateNewSelected(true);
  };

  const onCreateTemplateAssignmentFlowSelect = () => {
    setIsFlowSelected(true);
    setIsCreateTemplateSelected(true);
    setIsCreateNewSelected(false);
  };

  return (
    <div className={styles.wrapper}>
      <Tooltip placement="bottom-end" title={<TooltipTitle />}>
        <Button
          onClick={onCreateTemplateAssignmentFlowSelect}
          color="pink"
          data-intercom-target="Assignment Create Dialog Student Work Insights Button"
        >
          Student work insights
        </Button>
      </Tooltip>
      <Button
        onClick={onCreateNewAssignmentFlowSelect}
        color="pink"
        data-intercom-target="Assignments Create Dialog Do It Yourself Button"
      >
        Do it yourself
      </Button>
    </div>
  );
};

export default AssignmentCreateSelectFlow;
